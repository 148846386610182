/* eslint-disable */
import '../styles/components/header.scss'

import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useLocation } from '@reach/router'

import { FormattedMessage } from 'react-intl'

const Header = ({ pageContext }) => {
  const location = useLocation()
  const { lang } = pageContext || {}

  // 语言切换
  const switchLang = () => {
    const toLang = lang === 'zh' ? 'en' : 'zh'
    if (location.pathname === '/') {
      navigate('/en')
    } else {
      const url = location.pathname.replace(/(zh|en)/, toLang) + location.search + location.hash
      navigate(url)
    }
  }

  // header主题设置
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    // 获取当前滚动的高度
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop
    setScrollTop(scrollTopVal)
    window.addEventListener("scroll", scrollListenerFunc)

    return () => {
      // 移除事件
      window.removeEventListener("scroll", scrollListenerFunc)
    }
  }, []);

  function scrollListenerFunc () {
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop
    setScrollTop(scrollTopVal)
  }

  return (
    <header className={`header-container simple-header ${scrollTop === 0 && 'header-transparent-theme' || ''}`}>
      <a href={`https://www.mogdb.io${lang === 'en' ? '/en' : ''}`} target="_blank" className="logo-box" alt="LOGO"></a>
      <div className="right-box">
        <div onClick={() => switchLang()} className="menu-link lang-link"><FormattedMessage id="header.lang" /></div>
      </div>
    </header>
  )
}

export default Header