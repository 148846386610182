import { graphql, useStaticQuery } from 'gatsby'

import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'

function Seo({ pageContext, title, link, description, meta }) {
  const { lang, version, docType, customweight } = pageContext || {}
  
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const imgUrl = 'https://docs.mogdb.io/logo-r.png'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'docsearch:lang',
          content: lang,
        },
        {
          name: 'docsearch:customweight',
          content: customweight,
        },
        {
          name: 'docsearch:product',
          content: docType,
        },
        {
          name: 'docsearch:version',
          content: version,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: imgUrl,
        },
        {
          property: 'og:image:width',
          content: '400',
        },
        {
          property: 'og:image:height',
          content: '400',
        }
      ].concat(meta)}
      link={[
        {
          href: '/favicon.ico',
          rel: 'shortcut icon',
          type: 'image/x-icon',
        },
        { link },
      ].concat(link)}
      script={[
        {
          src: '/iconfont.js',
          type: 'text/javascript'
        }
      ]}
    />
  )
}

Seo.defaultProps = {
  meta: [],
  link: [],
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
