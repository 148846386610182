/* eslint-disable */
import '../styles/components/header.scss'

import React, { useState, Fragment } from "react"
import { Link, navigate } from "gatsby"
import { useLocation } from '@reach/router'

import { FormattedMessage } from 'react-intl'
import { Popover } from 'antd'

import Icon from './iconfontCpt'
import SearchInput from "./seachInput"
import { allToolsMenu, allProductLabels } from '../utils/menu_config'

const Header = ({ pageContext }) => {
  const location = useLocation()
  const { lang, docType } = pageContext || {}
  // 配置产品列表
  const goToolsDocs = (type, link) => {
    const url = link ? `${link}&lang=${lang}` : `/${lang}/${type}`
    setMediaMenuVisible(false)
    navigate(url)
  }

  // 获取菜单
  const menuKeys = ['mogdb', 'mogdb-stack', 'uqbar']
  
  // 获取文档工具菜单
  const doctools = allToolsMenu.find(item => item.key === 'doc_tools')
  const [doctoolsVisible, setDoctoolsVisible] = useState(false)
  const handleVisibleChange = (newVisible) => {
    setDoctoolsVisible(newVisible)
  }
  const doctoolsContent = (
    <div className="tools-down-menu">
      {doctools.menu.map((item, idx) => (
        <a className={`product-box ${item.value} ${item.disabled ? 'disabled' : ''}`} key={idx} href={`${item.link}&lang=${lang}`} target="_blank" onClick={() => setDoctoolsVisible(false)}>
          <div className='tool-icon'><Icon type={`d_${item.value}${item.disabled ? '_disabled': ''}`} size={22}/></div>
          <div className='info'>
            <div className="title">{item[lang]}</div>
          </div>
          <Icon customClass="jump-icon" type="d_jump" size={14}/>
        </a>
      ))}
    </div>
  )
  // 获取生态工具菜单
  const ecologicalTools = allToolsMenu.filter(item => item.isEcologicalTools)
  const toolsContent = (
    <div className="tools-down-menu">
      {ecologicalTools.map((item, idx) => (
        <div key={idx}>
          <div className="type-title">{item[lang]}</div>
          {item.menu.map((m, i) => (
            <div className={`product-box ${m.value} ${docType === m.value ? 'is-active' : ''} ${m.disabled ? 'disabled' : ''}`} key={i} onClick={() => goToolsDocs(m.value)}>
              <div className='tool-icon'><Icon type={`d_${m.value}${m.disabled ? '_disabled': ''}`} size={22}/></div>
              <div className='info'>
                <div className="title">{m.label}</div>
                <div className="desc">{m[lang]}</div>
              </div>
              <Icon customClass="jump-icon" type="d_jump" size={14}/>
            </div>
          ))}
        </div>
      ))}
    </div>
  )

  // 语言切换
  const switchLang = () => {
    const toLang = lang === 'zh' ? 'en' : 'zh'
    if (location.pathname === '/') {
      navigate('/en')
    } else {
      const url = location.pathname.replace(/(zh|en)/, toLang) + location.search + location.hash
      navigate(url)
    }
  }

  // 移动端产品菜单
  const [mediaMenuVisible, setMediaMenuVisible] = useState(false)
  const [activeMediaMenuTab, setMediaMenuActiveTab] = useState('database')
  const allMediaMenu = allToolsMenu.filter(item => !item.mediaHeaderDisbled)

  // 处理遮罩层点击
  function handelMaskClick() {
    setMediaMenuVisible(false)
    document.body.style.overflow = 'auto'
  }
  function handelBoxClick(e) {
    e.stopPropagation();
  }

  return (
    <header className="header-container">
      {/* left content */}
      <a href={`https://www.mogdb.io${lang === 'en' ? '/en' : ''}`} target="_blank" className="logo-box" alt="LOGO"></a>
      <Link to={`${lang === 'en' ? '/en' : '/'}`} className={`menu-link ${!docType ? 'is-active' : ''}`}><FormattedMessage id="header.index" /></Link>
      {menuKeys.map((key, idx) => (
        !allProductLabels[key].disabled && <Link key={idx} className={`menu-link ${docType === allProductLabels[key].value ? 'is-active' : ''}`} to={`/${lang}/${allProductLabels[key].value}/`}>{allProductLabels[key].label}</Link>
      ))}
      <Popover overlayClassName="header-dropdown-box" placement="bottomRight" content={toolsContent} trigger="click">
        <div className={`menu-link header-drop-link ${(docType && !['mogdb', 'mogdb-stack', 'uqbar'].includes(docType)) ? 'is-active' : ''}`}>
          <span><FormattedMessage id="header.ecologicaltools" /></span>
          <Icon customClass="toggle-icon" type="d_arraw_right" size={10}/>
        </div>
      </Popover>
      <Popover overlayClassName="header-dropdown-box doc-tools-dropdown" placement="bottomRight" content={doctoolsContent} visible={doctoolsVisible} onVisibleChange={handleVisibleChange} trigger="click">
        <div className="menu-link header-drop-link">
          <span><FormattedMessage id="header.doctools" /></span>
          <Icon customClass="toggle-icon" type="d_arraw_right" size={10}/>
        </div>
      </Popover>

      {/* right content */}
      <div className="right-box">
        {docType && <SearchInput pageContext={pageContext} />}
        <div onClick={() => switchLang()} className="menu-link lang-link"><FormattedMessage id="header.lang" /></div>

        <Link to={`${lang === 'en' ? '/en' : '/'}`} className="media-index-btn"><Icon type="d_home" size={16}/></Link>
        <div className="media-tools-btn" onClick={() => setMediaMenuVisible(true)}><Icon type="d_menu" size={16}/></div>
        {mediaMenuVisible &&
          <div className="media-tools-box-mask" onClick={e => handelMaskClick(e)}>
            <div className="close-btn"><Icon type="d_close" size={15}/></div>
            <div className="media-tools-box" onClick={e => handelBoxClick(e)}>
              <div className="tab-box">
                {allMediaMenu.map((item, idx) => (
                  <div key={idx} className={`${activeMediaMenuTab === item.key ? 'is-active' : ''} tab-item`} onClick={() => setMediaMenuActiveTab(item.key)}>{item[lang].replace('Management ', '')}</div>
                ))}
              </div>
              <div className="tab-content-box">
                {allMediaMenu.map((item, idx) => (
                  <Fragment key={idx}>
                    {activeMediaMenuTab === item.key && item.menu.map((m, i) => (
                      <div className={`product-box ${item.key} ${m.value} ${docType === m.value ? 'is-active' : ''} ${m.disabled ? 'disabled' : ''}`} key={i} onClick={() => goToolsDocs(m.value, m.link)}>
                        <div className='tool-icon'><Icon type={`d_${m.value}${m.disabled ? '_disabled': ''}`} size={28}/></div>
                        <div className='info'>
                          <div className="title">{m.label || m[lang]}</div>
                          {m.label && <div className="desc">{m[lang]}</div>}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        }
      </div>
    </header>
  )
}

export default Header