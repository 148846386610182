import React from 'react'
import { IntlProvider } from 'react-intl'
import flat from 'flat'
import langMap from '../intl'

import Header from './header'
import SimpleHeader from './simpleHeader'
import Footer from './footer'

const Layout = ({ pageContext, useSimpleHeader, children }) => {
  const lang = (pageContext && pageContext.lang) || 'zh'
  return (
    <IntlProvider locale={lang} messages={flat(langMap[lang])}>
      { useSimpleHeader ? <SimpleHeader pageContext={pageContext} />: <Header pageContext={pageContext} />}
      <main className="main-container">
        {children}
        <Footer pageContext={pageContext} />
      </main>
    </IntlProvider>
  )
}

export default Layout
