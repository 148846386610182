import '../styles/components/search.scss'
import '@docsearch/css'

import React from "react"
import { DocSearch } from '@docsearch/react'

const SearchBox = ({ pageContext }) => {
  const { lang, version, docType } = pageContext || {}

  const SearchKeys = {
    appId: process.env.GATSBY_API_APPID,
    apiKey: process.env.GATSBY_API_APIKEY,
    indexName: process.env.GATSBY_API_INDEXNAME,
    searchParameters: {
      facetFilters: [`lang:${lang}`, `version:${version}`, `product:${docType}`],
    }
  }

  return (
    <DocSearch
      appId={SearchKeys.appId}
      indexName={SearchKeys.indexName}
      apiKey={SearchKeys.apiKey}
      searchParameters={SearchKeys.searchParameters}
    />
  )
}

export default SearchBox