/**
 * 菜单设置，控制导航栏的菜单展示
 * isEcologicalTools: 生态工具，用于pc header 展示
 * mediaHeaderDisbled: 移动端不展示该菜单
 */
const allToolsMenu = [
  {
    zh: '数据库',
    en: 'Database',
    key: 'database',
    menu: [
      {
        label: 'MogDB',
        value: 'mogdb',
        en: 'A commercial database developed by ENMOTECH based on openGauss open source database.',
        zh: '基于openGauss开源数据库进行定制，推出的企业发行版。',
        disabled: false
      },
      {
        label: 'Uqbar',
        value: 'uqbar',
        en: 'HTAP Time Series Database',
        zh: '超融合时序数据库',
        long_en: 'A time-series database developed based on the openGauss kernel. ',
        long_zh: '基于MogDB开发的时序数据库。',
        disabled: false
      }
    ]
  },
  {
    zh: '开发工具',
    en: 'Development Tools',
    key: 'dev_tools',
    mediaHeaderDisbled: true,
    menu: [
      {
        label: 'Mogeaver',
        value: 'mogeaver',
        zh: '图形化开发工具',
        en: 'Graphical Tool',
        disabled: false,
        link: 'mogdb/latest/mogeaver-overview'
      }
    ]
  },
  {
    zh: 'Migrate to MogDB解决方案',
    en: 'Migrate to MogDB Solution',
    key: 'o2o',
    isEcologicalTools: true,
    menu: [
      {
        label: 'MTK',
        value: 'mtk',
        zh: '异构数据库迁移',
        en: 'Database Migration Toolkit',
        disabled: false
      },
      {
        label: 'MDB',
        value: 'mdb',
        zh: '异构数据同步',
        en: 'MogDB Data Bridge',
        disabled: false
      }, {
        label: 'SCA',
        value: 'sca',
        zh: '兼容性分析',
        en: 'SQL Compatibility Analyzer',
        disabled: false
      }, {
        label: 'MVD',
        value: 'mvd',
        zh: '数据一致性校验',
        en: 'MogDB Verify Data',
        disabled: false
      }
    ]
  },
  {
    zh: '管理工具',
    en: 'Management Tools',
    key: 'tools',
    isEcologicalTools: true,
    menu: [
      {
        label: 'MogHA',
        value: 'mogha',
        zh: '高可用管理',
        en: 'High Availability',
        disabled: false
      }, {
        label: 'zCloud for MogDB',
        value: 'zcloud-for-mogdb',
        zh: '企业级运维管理平台',
        en: 'O&M Platform',
        disabled: false
      }, {
        label: 'PTK',
        value: 'ptk',
        zh: '部署运维工具',
        en: 'Provisioning Toolkit',
        disabled: false
      },
      {
        label: 'MogDB Stack',
        value: 'mogdb-stack',
        en: 'MogDB on Kubernetes',
        zh: 'MogDB on Kubernetes',
        disabled: false
      },
    ]
  },
  {
    zh: '文档工具',
    en: 'Doc Tools',
    key: 'doc_tools',
    menu: [
      {
        value: 'parameter',
        zh: '参数对比',
        en: 'Parameter Comparison',
        disabled: false,
        aliaszh: '其他工具',
        aliasen: 'Other Tools',
        isExternalLink: true,
        link: 'https://kit.mogdb.io?theme=light'
      }
    ]
  }
]

/**
 * 产品 对应的key与label
 * 
 * 菜单 中英文对应
 */
const allProductLabels = {}
const allProductTypes = {}
allToolsMenu.forEach(i => {
  allProductTypes[i.key] = {
    zh: i.zh,
    en: i.en,
  }
  i.menu.forEach(p => {
    allProductLabels[p.value] = p
  })
})

module.exports = {
  allToolsMenu,
  allProductLabels,
  allProductTypes
}
