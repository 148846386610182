import PropTypes from 'prop-types'
import React from 'react'
function Icon({ size, type, customClass }) {
  const proportion = ((1 * size) / 14).toFixed(2)
  const iconStyle = {
    width: proportion + 'rem',
    height: proportion + 'rem'
  }
  function getIcon (type) {
    if (['d_mogdb', 'd_uqbar'].includes(type)) {
      return <i className={`iconfont ${type} ${customClass}`} style={iconStyle}></i>
    } else {
      return (
        <svg className={`iconfont ${customClass}`} style={iconStyle} aria-hidden="true">
          <use href={'#' + type}></use>
        </svg>
      )
    }
  }
  return getIcon(type)
}

Icon.defaultProps = {
  size: 16,
  mogdbCustomClass: '',
  customClass: ''
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
  mogdbCustomClass: PropTypes.string,
  customClass: PropTypes.string
}

export default Icon
